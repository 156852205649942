@mixin css-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin animation($str) {
  @include css-prefix(animation, $str);
}

@mixin background-gradient($direction, $startColor, $startColorAmount, $endColor, $endColorAmount) {
  background: linear-gradient($direction, $startColor $startColorAmount, $endColor $endColorAmount);
  background-color: $startColor;
  filter: progid:dximagetransform.microsoft.gradient(startColorStr= '#{$startColor}', endColorStr='#{$endColor}');
}

@mixin hide-text {
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin placeholder {
  &:placeholder { /* stylelint-disable-line */
    opacity: 1;
    @content;
  }

  &::placeholder {
    @content;
  }
}

@mixin pre-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin text-overflow-ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin transform($transforms) {
  @include css-prefix(transform, $transforms);
}
